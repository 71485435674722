  
 

   @media (max-width: 768px) {
    h3 {
      font-size: 24px;
    }
    .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .center .slick-center h3 {
      color: #e67e22;
      opacity: 1;
      transform: scale(1);
    }
    .center h3 {
      opacity: 0.8;
      transform: scale(0.95);
      transition: all 300ms ease;
    }
  }
  
  .slick-vertical .slick-slide {
    height: 180px;
  }



  @media (min-width: 600px) {
    .slick-list {
      max-width: 76vw !important;  
    } 
  }
  
  
  .slick-slide{
    /* width: 19vw !important; */
    padding: 0.5vw;
  }

  @media (min-width: 970px) {
    .slick-prev  {
      top:180px !important; 
    } 
  }

  
  @media (min-width: 600px) {
    .slick-prev {
      top:230px !important; 
    } 
    .slick-next {
      top:230px !important; 
    } 
  }
  @media (min-width: 960px) {
    .slick-prev {
      top:175px !important; 
    } 
    .slick-next {
      top:175px !important; 
    } 
  }
  @media (min-width: 1280px){
    .slick-prev {
      top:250px !important;
    }
    .slick-next {
      top:250px !important;
    }
  }
  @media (min-width: 1366px){
    .slick-prev {
      top:280px !important;
    }
    .slick-next {
      top:280px !important;
    }
  }

  .slick-prev {
    z-index: 100;
    left: -25px !important; 
  }

  .slick-next {
    z-index: 100;
    right: 13px !important;
  }

  .slick-prev:before {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 24px !important; 
    height: 100px !important;
    width: 100px !important; 
    background: url('../../img/arrow_left.svg') no-repeat;
    display: block !important;
    content: "" !important;
}
.slick-next:before {
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 24px !important; 
    height: 100px !important;
    width: 100px !important; 
    background: url('../../img/arrow_right.svg') no-repeat;
    display: block !important;
    content: "" !important;
}



  