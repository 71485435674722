body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clearfix:before,
.clearfix:after {
   content: " ";
   display: table;
}

.clearfix:after {
   clear: both;
}

.clearfix {
   *zoom: 1;
}

*, *:before, *:after {
  box-sizing: border-box;
}


#img-about {
  -webkit-transition: 'all 1.5s ease-in-out';
  transition: 'all 1.5s ease-in-out';
}

#img-about:hover  {
  -webkit-transform: 'translate(-40%)';
          transform: 'translate(-40%)';
}

  
 

   @media (max-width: 768px) {
    h3 {
      font-size: 24px;
    }
    .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .center .slick-center h3 {
      color: #e67e22;
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    .center h3 {
      opacity: 0.8;
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }
  }
  
  .slick-vertical .slick-slide {
    height: 180px;
  }



  @media (min-width: 600px) {
    .slick-list {
      max-width: 76vw !important;  
    } 
  }
  
  
  .slick-slide{
    /* width: 19vw !important; */
    padding: 0.5vw;
  }

  @media (min-width: 970px) {
    .slick-prev  {
      top:180px !important; 
    } 
  }

  
  @media (min-width: 600px) {
    .slick-prev {
      top:230px !important; 
    } 
    .slick-next {
      top:230px !important; 
    } 
  }
  @media (min-width: 960px) {
    .slick-prev {
      top:175px !important; 
    } 
    .slick-next {
      top:175px !important; 
    } 
  }
  @media (min-width: 1280px){
    .slick-prev {
      top:250px !important;
    }
    .slick-next {
      top:250px !important;
    }
  }
  @media (min-width: 1366px){
    .slick-prev {
      top:280px !important;
    }
    .slick-next {
      top:280px !important;
    }
  }

  .slick-prev {
    z-index: 100;
    left: -25px !important; 
  }

  .slick-next {
    z-index: 100;
    right: 13px !important;
  }

  .slick-prev:before {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 24px !important; 
    height: 100px !important;
    width: 100px !important; 
    background: url(/static/media/arrow_left.d65c76b6.svg) no-repeat;
    display: block !important;
    content: "" !important;
}
.slick-next:before {
    color: rgba(0, 0, 0, 0.3) !important;
    font-size: 24px !important; 
    height: 100px !important;
    width: 100px !important; 
    background: url(/static/media/arrow_right.9778ac07.svg) no-repeat;
    display: block !important;
    content: "" !important;
}



  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




